import { Box, CircularProgress } from "@mui/material";
import { ErrorHeader } from "../../shared/errorHeader";
import { useEffect, useState } from "react";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import dayjs from 'dayjs';
import { useApi } from "../../hooks/useApi";
import {GameForm} from "./gameForm";
import { BasicButton } from "../../shared/basicButton";
import { GameCommand, GameInfo } from "../../api/models/game";
import { addGameApi, deleteGameApi, getSingleOrgSeasonApi, updateGameApi, updateGameOpponentLogoApi } from "../../api/orgSeasonApi";
import { useParams } from "react-router-dom";
import { Team } from "../../api/models/team";
import { InfoBar } from "../../shared/infoBar";

export function GamePage() {

        const [orgSeasonName, setOrgSeasonName] = useState<string>('');
        const [teamName, setTeamName] = useState<string>('');
        const [games, setGames] = useState<GameInfo[] | undefined>(undefined);
        const [selectedGame, setSelectedGame] = useState<GameInfo | undefined>(undefined);

        const [showForm, setShowForm] = useState<boolean>(false);
        const [gameRows, setGameRows] = useState<RowItem[] | undefined>(undefined);
        const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const params = useParams();

        const getOrgSeasonRequest = useApi(getSingleOrgSeasonApi);
        const addGameRequest = useApi(addGameApi);
        const updateGameRequest = useApi(updateGameApi);
        const deleteGameRequest = useApi(deleteGameApi);
        const updateGameLogoRequest = useApi(updateGameOpponentLogoApi);
 

        const navigateUrl = (params.orgSeasonId && params.teamId) ? `/orgseason/${params.orgSeasonId}/team/${params.teamId}` : '';


            useEffect(() => {
                initPage();
            }, []);

            useEffect(() => {
              if (getOrgSeasonRequest.data) {
                const orgSeason = getOrgSeasonRequest.data;
                const team:Team = orgSeason.teams.find((team:Team) => team.id === params.teamId);
                if(team){
                    const response: GameInfo[] = team.games;
                    setOrgSeasonName(orgSeason.name);
                    setTeamName(team.name);
                    setGames(response);
                    mapGamesToRows(response);
                }
                
              }

          }, [getOrgSeasonRequest.data]);

          useEffect(() => {
            if(addGameRequest.data){
                setShowForm(false);
                initPage();
            }
        }, [addGameRequest.data]);

        useEffect(() => {
            if(updateGameRequest.data){
                setShowForm(false);
                initPage();
            }
        }, [updateGameRequest.data]);

        useEffect(() => {
            if(updateGameLogoRequest.data){
                setShowForm(false);
                initPage();
            }
        }, [updateGameLogoRequest.data]);

        useEffect(() => {
            if(deleteGameRequest.data){
                setShowForm(false);
                initPage();
            }
        }, [deleteGameRequest.data]);

        const initPage = async () => {
            try {
    
                setIsLoading(true);
                setErrorMessage('');

                
            if (!params.orgSeasonId) {
                throw Error('OrgSeasonId is missing');
            }

            if (!params.teamId) {
                throw Error('TeamId is missing');
            }

                await getOrgSeasonRequest.request(params.orgSeasonId);
            } 
            catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }
        }
    
        function mapGamesToRows(responseGames?: GameInfo[]) {
            if (responseGames && responseGames.length > 0) {
                const tempRows = responseGames.map((rowItem) => ({
                    id: rowItem.gameGuid.toString(),
                    items: [{ name: 'Logo', value:rowItem.opponentLogoPath},
                            { name: '', value: rowItem.isHome ? 'H' : 'A' },
                            { name: 'Date', value:dayjs(rowItem.gameTime).format('MM/DD/YY') }, 
                            { name: 'Time', value:dayjs(rowItem.gameTime).format('h:mm A') },
                            { name: 'Name', value: rowItem.opponentName},
                            { name: 'Location', value: rowItem.location},
                            { name: 'Score', value: rowItem.homeScore?.toString() ?? ''},
                            { name: 'OppScore', value: rowItem.visitorScore?.toString() ?? ''}
                            
                            
                        ]
                }));
    
                setGameRows(tempRows);
            }
        }

        function handleFormSubmit(data:GameCommand) {
            if(selectedGame && selectedGame.gameGuid){
                updateGameRequest.request(params.teamId, selectedGame.gameGuid, data);
            }
            else
            addGameRequest.request(params.teamId, data);
        }

        function handleImageUpload(image: string) {
            if(selectedGame && selectedGame.gameGuid){

                const imageData = {
                    image: image
                }

                updateGameLogoRequest.request(params.teamId, selectedGame.gameGuid, imageData);
            }
        }

        function handleFormClose() {
            setShowForm(false);
        }

        async function navigateToEditGamePage(gameId: string): Promise<boolean> {
            setSelectedGame(games?.find((game:GameInfo) => game.gameGuid.toString() === gameId));
            setShowForm(true);
            
            return true;
        }
    
        async function handleDeleteGame(gameGuid: string): Promise<boolean> {
            deleteGameRequest.request(params.teamId, gameGuid);
            
            return true;
        }

        

            

        function mainContent() {
            return (
                <Box>
                  <h1>{orgSeasonName} {teamName} Game Page</h1> 
                  {!showForm && <Box sx={{ margin: "10px" }} >
                  <BasicButton   onClick={() => setShowForm(true)} labelText={"Add Game"}/>

               </Box>}

                   {showForm && <GameForm selectedGame={selectedGame} 
                                          onImageUpload={handleImageUpload}
                                          onSubmit={handleFormSubmit} 
                                          onClose={handleFormClose} />}

                   
                    {gameRows && <MiniGrid rows={gameRows} width={1200} onEdit={navigateToEditGamePage} onDelete={handleDeleteGame}/>}
                  
                      
                </Box>);
        }

        return (<Box>
            <InfoBar navigateUrl={navigateUrl}  />
            <ErrorHeader errorMessage={errorMessage} />
            {getOrgSeasonRequest.loading && <CircularProgress />}
            {games && mainContent()}
        </Box>
        );

}