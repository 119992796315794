import { AppBar,  Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export function Footer() {
    return (
        <AppBar position="sticky" elevation={0} component="footer" color="primary">
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption">
                    <Link to="/orgs" style={{ color: "inherit", textDecoration: "none" }}>©2022 ColbrenSolutions</Link>
                    </Typography>
            </Toolbar>
        </AppBar>
    );
};

