import { Grid, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

export function SponsorPage() {

    const { isAuthenticated } = useAuth0();

    function renderHomePage() {
        return (
            <Box sx={{ width: '650px', height: '900px', padding: 2, border: '1px solid black', margin: 'auto', mt: 4,backgroundColor: '#f8f8f8' }}>
                <h1>Sponsorship Packages</h1>
                <h3>2025 WRHS Softball, Flag Football, JV and Varsity baseball (110 athletes)</h3>
                <span style={{ color: 'maroon' }}>Need commitment by 3/20 to get into the Gameday rosters</span>
                <br/><br/>
                <table>
                    <tr><td style={{ fontWeight: 'bold' }}>MVP Package - limit 1</td><td style={{ fontWeight: 'bold' }}>$500</td></tr>
                    <tr>
                        <td colSpan={2}>
                        <ul>
                            <li>Full Size Ad in Sports Card book</li>
                            <li>5 of your business cards in the book</li>
                            <li>Full page ad first page of  game program (min 2 games per sport)</li>
                            <li>Logo / link on my website for 1 year</li>
                            <li>invite for 2 to the banquet (where the cards are handed out)</li>
                        </ul>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>&nbsp;</td></tr>

                    <tr><td style={{ fontWeight: 'bold' }}>VIP Package - limit 4</td><td style={{ fontWeight: 'bold' }}>$150</td></tr>
                    <tr>
                        <td>
                        <ul>
                            <li>1/4 page Ad in Sports Card book</li>
                            <li>5 of your business cards in the book</li>
                            <li>1/4 pag ad in game program (min 2 games per sport)</li>
                            <li>Logo / link on my website for 1 year</li>
                        </ul>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>&nbsp;</td></tr>

                    <tr><td style={{ fontWeight: 'bold' }}>All Star Package - limit 10</td><td style={{ fontWeight: 'bold' }}>$75</td></tr>
                    <tr>
                        <td>
                        <ul>
                        <li>Logo and website in Sports Card book</li>
                        <li>Logo and website in game program (min 2 games per sport)</li>
                        </ul>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>&nbsp;</td></tr>
                </table>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <p>Send info or inquiries to:<br/>
                        <span style={{ fontWeight: 'bold' }}>Jeff May</span>
                        <br/><a href="mailto:info@colbrensports.com">info@colbrensports.com</a></p>
                    </Grid>
                    <Grid item>
                        <img src="/images/venmo_qr_image.png" alt="sponsorship" /><br/>Venmo Jeff-May-13
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box >
            {renderHomePage()}
        </Box>
    );
}