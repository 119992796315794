
import { Grid, Button, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';


export function HomePage() {

    const { isAuthenticated } = useAuth0();

    const navigate = useNavigate();

    function viewOrgHandler() {
        navigate(`/orgs`);
    };

    function sponsorPageHandler() {
        navigate(`/sponsor`);
    };


    function renderHomePage() {
        return (
            <Grid container direction='column' spacing={4}>
                <br/><br/>

                <Grid item container spacing={2}>
                    <Box>
                        <h2>The Mission</h2>
                        <p>Deliver an improved youth sports experience for athletes, coaches and fans by 
                            creating tools and processes to generate rosters, programs, player cards, profile photos and more.
                        </p>
                    </Box>

                </Grid>

                <Grid item container spacing={2}>
                
                    <Grid item xs={12}>
                        <h2>The Cards</h2>
                        <strong>Every leader and athlete gets a keepsake binder at no cost</strong>
                        <p>The binder includes a copy of the team roster,
                            game schedule, a sports card for every athlete and leader on the team. They also get copies of their own card.
                            This is a gift to the athlete and leader and is a great way to remember the season and the team.
                            Sponsors can put ads into the books to cover their cost.
                        </p>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                <img src='/images/card-book/Image1.jpg' height='200' alt='front cover' />
                                <img src='/images/card-book/Image2.jpg' height='200' alt='front cover' />
                                <img src='/images/card-book/Image3.jpg' height='200' alt='front cover' />
                                <img src='/images/card-book/Image4.jpg' height='200' alt='front cover' />
                                <img src='/images/card-book/Image5.jpg' height='200' alt='front cover' />
                        </Box>
                        <br/><br/>
                    </Grid>
                    
                </Grid>

                <Grid item container spacing={2} id='program' sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2 }}>
                   
                    <Grid item xs={12}>
                        <h2>Rosters and Programs</h2>
                        <strong>Rosters allow parents and fans to build relationship with team</strong>
                        <p>When fans can put a name and face to the jersey number, they learn the athletes and are able
                            to relate to them. This is a great way to build a fan base and support for the team.
                            Rosters and programs can be used for sponsorhip and advertising which will help
                            pay for cards and rosters and possibly bring in additional funding.
                        </p>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                <img src='/images/programs/Image1.jpg' height='200' alt='front cover' />
                                <img src='/images/programs/Image2.jpg' height='200' alt='front cover' />
                                <img src='/images/programs/Image3.jpg' height='200' alt='front cover' />
                                <img src='/images/programs/Image4.jpg' height='200' alt='front cover' />
                                <img src='/images/programs/Image5.jpg' height='200' alt='front cover' />
                        </Box>
                        <br/><br/>
                    </Grid>
                </Grid>

                <Grid item container spacing={2} id='program' sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 2 }}>
                   
                   <Grid item xs={12}>
                       <h2>Profile Photos</h2>
                       <strong>Simple way to capture, resize and name profile shots</strong>
                       <p>Often you need a profile photo of each athlete. The common solution is to take 100 photos with a cell phone
                        and then try to associate the photo with the athlete. This is time consuming and error prone.
                        We have a simple solution that allows you to add athletes, then select their name and take photo and it will crop, resize and name the photo.
                        </p>
                       
                   </Grid>
               </Grid>
            </Grid>
        );
    }

    return (
        <Box>

            {/* <Box sx={{ margin: "10px" }} >
                <Button color="primary"
                    variant="contained"
                    onClick={e => viewOrgHandler()} >
                    View Orgs
                </Button>
            </Box> */}

            {renderHomePage()}

            {/* <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => orderFormPageHandler()} >
                        Order Products
                    </Button> 
            </Grid>*/}
            <br/>
            <Button color="primary" variant="contained" onClick={sponsorPageHandler}>
                How to Sponsor
            </Button>
            <br/><br/><br/>
            <Box>
                For more info: < a href='mailto:info@colbrensports.com'>info@colbrensports.com</a>
            </Box>
            <hr/>
            <h3>Sponsors</h3>
            <a href='https://cooperpoolsinc.com' target='_blank' rel='noreferrer'>
            <img src='/images/sponsors/CooperPools.png' height='100' alt='Cooper' />
            </a>

            <a href='https://www.baypaws.com/wesleychapel' target='_blank' rel='noreferrer'>
            <img src='/images/sponsors/baypaws.png' height='75' alt='Bay Paws' />
            </a>


        </Box>

    );
}