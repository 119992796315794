import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { deleteSponsorApi } from "../../api/orgSeasonApi";
import { useNavigate } from "react-router-dom";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { Sponsor } from "../../api/models/orgSeason";
import { NavButton } from "../../shared/navButton";
import { useOrgSeasonContext } from "../../context/orgSeasonContext";
import { BasicButton } from "../../shared/basicButton";


export function ManageSponsorsPanel() {

    const [sponsorRows, setSponsorRows] = useState<RowItem[] | undefined>(undefined);
    const [isComplete, setIsComplete] = useState<boolean>(false);

    const navigate = useNavigate();

    const deleteRequest = useApi(deleteSponsorApi);
    const { orgSeason, refreshOrgSeason } = useOrgSeasonContext();


    useEffect(() => {
        if (orgSeason) {
            mapSponsorsToRows(orgSeason.sponsors);
            setIsComplete(orgSeason.sponsors && orgSeason.sponsors.length > 3);
        }

    }, [orgSeason]);

    async function mapSponsorsToRows(responseItems: Sponsor[]) {
        if (responseItems && responseItems.length > 0) {
            const tempRows = responseItems.map((item) => ({
                id: item.id,
                items: [{ name: 'logo', value: item.logoLink }, 
                        { name: 'name', value: item.name },
                        { name: 'level', value: item.packageLevel }
                    ]
            }));

            setSponsorRows(tempRows);
        }
    }

    async function navigateToEditSponsorPage(id: string): Promise<boolean> {
        navigate(`/orgseason/${orgSeason?.id}/sponsor/${id}`);
        return true;
    }

    async function handleDeleteSponsor(sponsorId: string): Promise<boolean> {
        if (orgSeason) {
            await deleteRequest.request(orgSeason.id, sponsorId);
            refreshOrgSeason();
        }

        return true;
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Sponsors</h3></Grid>
        </Grid>
        <BasicCard>

            {orgSeason &&
                <Box>
                    <Grid container spacing={1} >
                        <Grid>
                            <NavButton labelText='Add' navUrl={`/orgseason/${orgSeason?.id}/sponsor`} />
                        </Grid>

                    </Grid>
                    {deleteRequest.error && <BasicErrorLabel errors={[deleteRequest.error]} />}
                    {sponsorRows && <MiniGrid rows={sponsorRows} onEdit={navigateToEditSponsorPage} onDelete={handleDeleteSponsor} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}