import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { GameCommand, GameInfo } from '../../api/models/game';
import { TimePicker } from '@mui/x-date-pickers';
import { UploadImageButton } from '../../shared/uploadImageButton';
import CloseIcon from '@mui/icons-material/Close';

type GameFormValues = {
  opponentName: string;
  gameTime: Dayjs;
  location: string;
  homeScore: string;
  visitorScore: string;
  isHome: boolean;
};

export interface GameFormProps {
  selectedGame?: GameInfo;
  onSubmit: (data: GameCommand) => void;
  onImageUpload: (image: string) => void;
  onClose: () => void;
}

export function GameForm(props: GameFormProps) {
  const { register, handleSubmit, watch, control } = useForm<GameFormValues>();
  const [loading, setLoading] = React.useState(false);
  const [logo, setLogo] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState('');
  const [initialFormData, setInitialFormData] = React.useState<GameFormValues>(
    getFormData(props.selectedGame)
  );

  //const watchLogoLink = watch('logoLink');

  const onChangeLogo = async (selectedImage: string): Promise<void> => {
    setLogo(selectedImage);
    props.onImageUpload(selectedImage);
  };

  function getFormData(selectedGame?: GameInfo): GameFormValues {
    const defaultGameTime = dayjs().hour(19).minute(0).second(0).millisecond(0);

    const data: GameFormValues = {
      gameTime: props.selectedGame?.gameTime
        ? dayjs(props.selectedGame.gameTime)
        : defaultGameTime,
      opponentName: props.selectedGame?.opponentName
        ? props.selectedGame.opponentName
        : '',
      location: props.selectedGame?.location ? props.selectedGame.location : '',
      homeScore: props.selectedGame?.homeScore
        ? props.selectedGame.homeScore.toString()
        : '',
      visitorScore: props.selectedGame?.visitorScore
        ? props.selectedGame.visitorScore.toString()
        : '',
      isHome: props.selectedGame?.isHome ? props.selectedGame.isHome : false,
    };
    return data;
  }

  const onSubmit: SubmitHandler<GameFormValues> = (data) => {
    setLoading(true);
    setError('');

    try {
      console.log(data);

      const gameInfo: GameCommand = {
        opponentName: data.opponentName,
        gameTime: data.gameTime.toDate(),
        location: data.location,
        homeScore: data.homeScore ? parseInt(data.homeScore) : undefined,
        visitorScore: data.visitorScore
          ? parseInt(data.visitorScore)
          : undefined,
        isHome: data.isHome,
        opponentLogo: logo ? logo : '',
      };

      props.onSubmit(gameInfo);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2} p={2} sx={{ position: 'relative', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', maxWidth: '400px'}}>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <br />

        <Controller
          name="opponentName"
          control={control}
          defaultValue={initialFormData.opponentName}
          rules={{ required: 'Opponent name is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="opponent name"
              error={!!error}
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 300 }}
            />
          )}
        />

        <br />
        <br />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="gameTime"
            control={control}
            rules={{
              required: { value: true, message: 'Game day is required' },
            }}
            defaultValue={initialFormData.gameTime}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="game time"
                value={field.value}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            )}
          />
        </LocalizationProvider>

        <br />
        <br />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="gameTime"
            control={control}
            defaultValue={initialFormData.gameTime}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label="Time"
                value={field.value}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            )}
          />
        </LocalizationProvider>

        <br />
        <br />

        <Controller
          name="location"
          control={control}
          defaultValue={initialFormData.location}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="location"
              error={!!error}
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 300 }}
            />
          )}
        />

        <br />
        <br />

        <Controller
          name="homeScore"
          control={control}
          defaultValue={initialFormData.homeScore}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="home score"
              error={!!error}
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br />
        <br />

        <Controller
          name="visitorScore"
          control={control}
          defaultValue={initialFormData.visitorScore}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="visitor score"
              error={!!error}
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br />
        <br />

        <Controller
          name="isHome"
          control={control}
          defaultValue={initialFormData.isHome}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Home Game"
            />
          )}
        />

        <br />
        <br />

        <Box>
          {logo && (
            <img
              src={logo}
              height={100}
              width={100}
              alt="logo"
              style={{ border: '1px solid #909090' }}
            />
          )}
          <UploadImageButton
            id="uploadImage"
            onChange={onChangeLogo}
            aspectRatio={1}
          />
        </Box>

        <br />
        <br />

        {!loading && (
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: '8px' }}
            >
              Submit
            </Button>
            <Button type="reset" variant="contained" color="primary">
              Clear
            </Button>
          </Box>
        )}
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </Box>
  );
}
